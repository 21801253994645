import React, { useContext } from 'react';
import classnames from 'classnames';

import ErrorBoundary from '@/src/components/Base/ErrorBoundary';

import ReactToolTip from '@/src/components/Base/reactToolTip';
import Converter from '../../../../sources/list/converter';
import OpenJawGroundTrafficDetail from '../../popups/openJawGroundTrafficDetail';
import { LIST_UBT_GROUP_TYPES, LIST_UBT_KEY_TYPES, ListUbtGenerator, UbtBoundary } from '../../../../ubt.v2/list';

import { ScopeInternationalContext } from '../../../../sources/list/scopeInternationalContext';
import { TimeUpdateTip } from '../../common/TimeUpdateTip';
import AirlineMatrixContainer from '../../../../containers/list/top/airlineMatrix/async';

const Header = ({
	prevCond,
	currentSegmentSeq,
	isBuildUp,
	toggleBuildUp,
	showAirlineMatrix,
	onToggleAirlineMatrix,
	showCityText,
	openJawCityIndex,
	groundTrafficParams,
	openJawGroundTrafficData,
	flightWay,
	rawSplitRoundFlightsSwitch,
}) => {
	const scopeInternational = useContext(ScopeInternationalContext);

	if (prevCond && prevCond.size > 0) {
		let segmentText = '';
		switch (prevCond.get('flightWay')) {
			case 'S':
				segmentText = '单程';
				break;
			case 'D':
				segmentText = isBuildUp ? '往返组合' : currentSegmentSeq === 0 ? '去程' : '返程';
				break;
			case 'M':
				segmentText = `第${'一二三四五六'.charAt(currentSegmentSeq)}程`;
				break;
			default:
				break;
		}

		const getCityName = (flied) => {
			let cityName = '',
				airportName = '';
			if (isBuildUp) {
				airportName = prevCond.getIn(['flightSegments', 0, `${flied}AirportName`]);
				cityName += prevCond.getIn(['flightSegments', 0, `${flied}CityName`]);
				airportName && !showCityText ? (cityName += `(${airportName})`) : '';
			} else {
				airportName = prevCond.getIn(['flightSegments', currentSegmentSeq, `${flied}AirportName`]);
				cityName += prevCond.getIn(['flightSegments', currentSegmentSeq, `${flied}CityName`]);
				airportName && !showCityText ? (cityName += `(${airportName})`) : '';
			}
			return cityName;
		};

		const departTime = isBuildUp
				? prevCond.getIn(['flightSegments', 0, 'departureDate'])
				: prevCond.getIn(['flightSegments', currentSegmentSeq, 'departureDate']),
			arrivalTime = prevCond.getIn(['flightSegments', currentSegmentSeq, 'departureDate']),
			departDate = Converter.remainMonthAndDay(departTime),
			arrivalDate = Converter.remainMonthAndDay(arrivalTime),
			week = Converter.remianWeek(departTime),
			departureName = getCityName('departure'),
			arrivalName = getCityName('arrival'),
			longNameLength = isBuildUp ? 32 : 38,
			openJawGroundTrafficText = `交通指南`,
			// 显示线下交通 要求1：有数据  要求2：仅在缺口城市所在程显示  要求3：一定在跳转后的列表页(groundTrafficParams不为空，对应Container中openJawCityIndex存在且为多程两个条件)
			displayGroundTraffciText =
				openJawGroundTrafficData &&
				openJawGroundTrafficData.size > 0 &&
				Object.keys(groundTrafficParams).length > 0 &&
				openJawCityIndex >= currentSegmentSeq * 2 &&
				openJawCityIndex < (currentSegmentSeq + 1) * 2;

		const getCharacterLength = (str) => {
			return str.replace(/[\u0391-\uFFE5]/g, 'aa').length;
		};

		const longCityName = () =>
			getCharacterLength(departureName) +
				getCharacterLength(arrivalName) +
				getCharacterLength(departDate + (isBuildUp ? arrivalDate : week)) +
				(displayGroundTraffciText ? getCharacterLength(openJawGroundTrafficText) : 0) >=
			longNameLength;

		return (
			<div className={classnames('result-header')}>
				<div className={`flight-part${longCityName() ? ' over-size' : ''}`}>
					<div className="left-part">
						<div className="lead">{segmentText}：</div>
						<div className="address">
							<span
								className={`depart${
									openJawCityIndex === 0 + currentSegmentSeq * 2 ? ' highlight' : ''
								}`}
							>
								{departureName}
							</span>
							<i className={isBuildUp ? 'arrow-gray-round-b' : 'arrow-gray-oneway-b'}></i>
							<span
								className={`arrive${
									openJawCityIndex === 1 + currentSegmentSeq * 2 ? ' highlight' : ''
								}`}
							>
								{arrivalName}
							</span>
							{displayGroundTraffciText ? (
								<span className="abbr" id={'GroundTrafficHeaderHover'}>
									{openJawGroundTrafficText}
								</span>
							) : null}
							{displayGroundTraffciText ? (
								<ReactToolTip
									key={`GroundTrafficRecommendHeader`}
									parentId={`GroundTrafficHeaderHover`}
									toolTipContent={
										<OpenJawGroundTrafficDetail
											groundTrafficData={openJawGroundTrafficData}
											groundTrafficParams={groundTrafficParams}
										/>
									}
								/>
							) : null}
							<div className="sub-msg">
								{isBuildUp ? (
									<div className="date">
										去 {departDate} 返 {arrivalDate}
									</div>
								) : (
									<div className="date">
										{departDate}
										<span className="week">{week}</span>
									</div>
								)}
								{scopeInternational ? (
									<div className="hint">所有起飞 / 到达时间均为当地时间</div>
								) : null}
							</div>
						</div>
					</div>
					<div className="right-part">
						<TimeUpdateTip />
						{scopeInternational ? (
							<div className={classnames('extra')}>
								<UbtBoundary
									tracker={ListUbtGenerator(
										LIST_UBT_GROUP_TYPES.MATRIX_TOGGLE,
										LIST_UBT_KEY_TYPES.MATRIX_TOGGLE,
										showAirlineMatrix,
										flightWay,
										currentSegmentSeq
									)}
								>
									<div
										className="airline-compare"
										u_key="airline_compare"
										u_remark={`切换航司比价表，切换之前[${showAirlineMatrix}]`}
										onClick={onToggleAirlineMatrix}
									>
										航司比价表<i className={showAirlineMatrix ? 'arrow-up' : 'arrow-down'}></i>
									</div>
								</UbtBoundary>

								{prevCond.get('flightWay') == 'D' && !rawSplitRoundFlightsSwitch ? (
									<div className="tabs">
										<UbtBoundary
											tracker={ListUbtGenerator(
												LIST_UBT_GROUP_TYPES.COMMON_CLICK,
												LIST_UBT_KEY_TYPES.SORT_CLICK,
												'free_style'
											)}
										>
											<span
												u_key="build_up"
												u_remark="往返航程，自由搭配"
												className={`tab ${!isBuildUp ? 'active' : ''}`}
												onClick={toggleBuildUp.bind(this, false)}
											>
												自由搭配
											</span>
										</UbtBoundary>
										<UbtBoundary
											tracker={ListUbtGenerator(
												LIST_UBT_GROUP_TYPES.COMMON_CLICK,
												LIST_UBT_KEY_TYPES.SORT_CLICK,
												'build_up'
											)}
										>
											<span
												u_key="build_up"
												u_remark="往返航程，往返组合"
												className={`tab ${isBuildUp ? 'active' : ''}`}
												onClick={toggleBuildUp.bind(this, true)}
											>
												往返组合
											</span>
										</UbtBoundary>
									</div>
								) : null}
							</div>
						) : null}
					</div>
				</div>
				<ErrorBoundary>{scopeInternational && <AirlineMatrixContainer />}</ErrorBoundary>
			</div>
		);
	} else {
		return null;
	}
};

export default Header;
