import React from 'react';
import { connect } from 'react-redux';
import qs from 'qs';
import moment from 'moment';

import Header from '../../../../components/list/result/header/header';
import Converter from '../../../../sources/list/converter';
import { searchNoResultRecommendSelector } from '../recommend/searchNoResultRecommendSelector';
import { getGlobalSearchCriteria } from '../../selector/search.js';
import {
	filterShowAirlineMatrixSelector,
	isSearchSubAirportSelector,
	searchFlightsIsFinishedSelector,
	isBuildUpSelector,
	currentSegmentSeqSelector,
	containsTaxSelector,
	prevCondSelector,
	getShowCityTextSelector,
	getOpenJawGroundTrafficDataSelector,
	getSortTypesSelector,
	globalSwitchSelector,
	getFlightWaySelector,
	getRawSplitRoundFlightsSwitchSelector,
} from '../flight/baseSelector';
import { fetchOpenJawGroudTraffic } from '../../../../actions/list/recommend.js';
import { LIST_UBT_KEY_TYPES } from '../../../../ubt.v2/list';
import { genSwitchIncludeTax } from '../../../../actions/list/sort';
import { genToggleAirlineMatrix } from '../../../../actions/list/filter';
import { onToggleBuildUp } from '../../../../sources/list/onToggleBuildUp';

class HeaderContainer extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			openJawCityIndex: null,
			groundTrafficParams: {},
		};
	}

	componentDidMount() {
		const openJawCityIndex = parseInt(Converter.getListUrlParamByName('openjawcitysequence'));
		// 缺口程推荐点击推荐进入多程列表页
		if (openJawCityIndex !== null && openJawCityIndex >= 0 && this.props.searchCriteria.get('flightWay') === 'M') {
			this.setState({ openJawCityIndex: openJawCityIndex });
			let groundTrafficParams = {},
				openJawDepartureIndex = openJawCityIndex % 2 !== 0 ? openJawCityIndex : (openJawCityIndex + 3) % 4,
				openJawArrivalIndex = openJawCityIndex % 2 !== 0 ? (openJawCityIndex + 1) % 4 : openJawCityIndex,
				cityList = [],
				cityItem = {};
			this.props.searchCriteria.get('flightSegments').forEach((flightSegment, index) => {
				let searchedDepartureDate = this.props.searchCriteria.getIn(['flightSegments', index, 'departureDate']);

				cityItem = {};
				cityItem.cityName = flightSegment.get('departureCityName');
				cityItem.cityCode = flightSegment.get('departureCityCode');
				cityItem.date = searchedDepartureDate;
				cityList.push(cityItem);
				cityItem = {};
				cityItem.cityName = flightSegment.get('arrivalCityName');
				cityItem.cityCode = flightSegment.get('arrivalCityCode');
				cityItem.date = searchedDepartureDate;
				cityList.push(cityItem);
			});
			groundTrafficParams.departureCityCode = cityList[openJawDepartureIndex].cityCode;
			groundTrafficParams.arrivalCityCode = cityList[openJawArrivalIndex].cityCode;
			groundTrafficParams.departureDate = moment(cityList[openJawCityIndex].date)
				.startOf('day')
				.format('YYYY-MM-DD HH:mm:ss'); //取缺口城市所在日期
			this.props.getOpenJawGroundTraffic(groundTrafficParams);
			groundTrafficParams.departureCityName = cityList[openJawDepartureIndex].cityName;
			groundTrafficParams.arrivalCityName = cityList[openJawArrivalIndex].cityName;
			groundTrafficParams.openJawCityIndex = openJawCityIndex;
			this.setState({ groundTrafficParams: groundTrafficParams });
		}

		//点击推荐进入列表页，原推荐信息上报
		let transactionID = qs.parse(window.location.search.substr(1)).txid;
		let ubtSourceData = localStorage.ubtSourceData;
		if (ubtSourceData) {
			try {
				ubtSourceData = JSON.parse(ubtSourceData);
				let ubtSourceDataOfTxid = ubtSourceData[transactionID];
				if (ubtSourceDataOfTxid && Object.keys(ubtSourceDataOfTxid).length > 0) {
					LIST_UBT_KEY_TYPES.RECOMMEND_FLIGHT_RESOURCE.value.invoke(ubtSourceDataOfTxid);
					delete ubtSourceData[transactionID];
					localStorage.ubtSourceData = JSON.stringify(ubtSourceData);
				}
			} catch (e) {
				console.error(e);
			}
		}
	}

	render() {
		return this.props.searchNoResultRecommend ? null : (
			<Header
				{...this.props}
				openJawCityIndex={this.state.openJawCityIndex}
				groundTrafficParams={this.state.groundTrafficParams}
			/>
		);
	}
}

const mapStateToProps = (state) => ({
	sortTypes: getSortTypesSelector(state),
	currentSegmentSeq: currentSegmentSeqSelector(state),
	isBuildUp: isBuildUpSelector(state),
	prevCond: prevCondSelector(state),
	showCityText: getShowCityTextSelector(state),
	isSearchSubAirport: isSearchSubAirportSelector(state),
	searchNoResultRecommend: searchNoResultRecommendSelector(state),
	showAirlineMatrix: filterShowAirlineMatrixSelector(state),
	searchFlightsIsFinished: searchFlightsIsFinishedSelector(state),
	containsTax: containsTaxSelector(state),
	searchCriteria: getGlobalSearchCriteria(state),
	openJawGroundTrafficData: getOpenJawGroundTrafficDataSelector(state),
	globalSwitch: globalSwitchSelector(state),
	flightWay: getFlightWaySelector(state),
	rawSplitRoundFlightsSwitch: getRawSplitRoundFlightsSwitchSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
	toggleBuildUp: (isBuildUp) => {
		onToggleBuildUp(isBuildUp);
	},
	onToggleAirlineMatrix: () => {
		dispatch(genToggleAirlineMatrix());
	},
	switchContainTax: () => {
		dispatch(genSwitchIncludeTax());
	},
	getOpenJawGroundTraffic: (params) => {
		dispatch(fetchOpenJawGroudTraffic(params));
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderContainer);
