import React from 'react';
import Immutable from 'immutable'
import Converter from '../../../sources/list/converter'
import { OPEN_JAW_GROUND_TRAFFIC_TITLE, OPEN_JAW_GROUND_TRAFFIC_TYPE } from '../../../constants/list/enums/recommend.js'

const OpenJawGroundTrafficDetail = ({ groundTrafficParams, groundTrafficData }) => {
    const filterGroundTrafficDatas = (groundTrafficData) => {
        if (groundTrafficData.size > 2) {
            let carIndex = groundTrafficData.findIndex((data) => {
                if (OPEN_JAW_GROUND_TRAFFIC_TYPE.get(data.get("type")) == OPEN_JAW_GROUND_TRAFFIC_TYPE.CAR) {
                    return true
                }
            }),
                newGroundTrafficData = Immutable.List()
            if (carIndex >= 0) {
                newGroundTrafficData = newGroundTrafficData.push(groundTrafficData.get(carIndex))
                newGroundTrafficData = newGroundTrafficData.push(groundTrafficData.get(carIndex === 0 ? 1 : 0))
            } else {
                newGroundTrafficData = groundTrafficData.slice(0, 2)
            }
            return newGroundTrafficData
        }
        return groundTrafficData
    }
    const getTableContent = () => {
        let groundTrafficDataFiltered = filterGroundTrafficDatas(groundTrafficData)
        return OPEN_JAW_GROUND_TRAFFIC_TITLE.enums.map((title, index) => {
            return (
                <tr key={`groundTrafficTr${index}`}>
                    <th>{title.value.title}</th>
                    {groundTrafficDataFiltered.map((groundTrafficDataItem, index) => {
                        if (title.value.dataKey === "type") {
                            return <td key={`groundTrafficTd${index}`}>{groundTrafficDataItem.get("type") ? OPEN_JAW_GROUND_TRAFFIC_TYPE.get(groundTrafficDataItem.get("type")).value.name : "-"}</td>
                        }
                        if (title.value.dataKey === "distance") {
                            return <td key={`groundTrafficTd${index}`}>{groundTrafficDataItem.get("distance") ? `约${groundTrafficDataItem.get("distance")}km` : "-"}</td>
                        }
                        if (title.value.dataKey === "time") {
                            return <td key={`groundTrafficTd${index}`}>{groundTrafficDataItem.get("time") ? `约${Converter.formatMinAsDayHourMin(groundTrafficDataItem.get("time"))}` : "-"}</td>
                        }
                        if (title.value.dataKey === "price") {
                            return <td key={`groundTrafficTd${index}`}>{groundTrafficDataItem.get("price") && groundTrafficDataItem.get("price") > 0 ? `约${groundTrafficDataItem.get("price")}元` : "-"}</td>
                        }
                    })}
                </tr>
            )
        })
    }
    return (
        <div className="tooltip tooltip-insuff">
            <div className="tooltip-content">
                <h3>{groundTrafficParams.departureCityName}-{groundTrafficParams.arrivalCityName}交通指南：</h3>
                <div className="table">
                    <table>
                        <tbody>
                            {getTableContent()}
                            <tr>
                                <th>备注</th>
                                <td colSpan={2}>交通时间、票价为预计值，以实际为准，请预留充足时间。</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default OpenJawGroundTrafficDetail